/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-25",
    versionChannel: "nightly",
    buildDate: "2024-06-25T00:05:32.860Z",
    commitHash: "e83e39ad27aacde29583d51ed0ad234de4f370ef",
};
